import { Link } from 'gatsby';
import * as React from 'react';
import { css } from '@emotion/core';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const LogoGradient = require('../../content/img/logo_gradient.svg');

const SiteNavLogoStyles = css`
  flex-shrink: 0;
  display: block;
  margin-right: 40px;

  @media (max-width: 400px) {
    margin-right: 0px;
  }

  :hover {
    text-decoration: none;
  }

  img {
    display: inline;
    width: auto;
    height: 95px;
  }

  svg {
    height: 14px;
  }
`;

const SiteNavLogo = () => (
  <Link className="site-nav-logo" css={SiteNavLogoStyles} to="/">
    <img src={LogoGradient} alt="Logo" />
  </Link>
);

export default SiteNavLogo;
